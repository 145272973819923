export default class TermsAndConditionsText {


    public static html = `


    <style>
    p {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 5px;
  }
  
  h1 {
  margin-top: 40px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 5px;
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  }
  
  h2 {
  margin-top: 40px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 5px;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  }
  
  h3 {
  margin-top: 40px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 5px;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  }
  
  ol {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height:150%;
  margin-left: 20px;
  }
  
  li {
  margin-top: 5px;
  
  }
  
  
  </style>
  </head>
  
  <body>
  
  
      <div >
  
        <h3>Motifray platform</h3>
        <h1>Terms and Conditions</h1>
        <p>Last updated: 22 July, 2024</p>
  
        <p>
        <h2>§1. Definitions</h2>
      <ol>
        <li><b>Account</b> - a permission attributed to the Login, allowing the use of the application within Services in Motifray. There are two types of Accounts: 
          <br>a) Administrator account - technical account for each Service Recipient;
          <br>b) User account - personal account for each User pointed by the Service Recipient. </li>       
        <li><b>Agreement</b> - an agreement concluded between the Service Provider and the Service Recipient for the provision of the Services </li>
        <li><b>Entrepreneur</b> - natural person, legal person and an entity without legal personality, conducting business or professional activities on their own behalf, who/which concluded an Agreement for the Service with the Service Provider for purposes directly related to the business or professional activity, or statutory objectives.</li>
        <li><b>Login</b> - ID of a User registered in Motifray in accordance with these Terms and Conditions. Through the Login, a registered User benefits from the Services made available through Motifray. The User Login is unique and Password-protected.</li>
        <li><b>Login Activation</b> - the moment the Service Provider activates the User Login; the Login Activation is confirmed with an email sent to the Entrepreneur's email address indicated in the Registration Form.</li>
        <li><b>Month</b> - a period of time ending next calendar month on the day before the day which name or date corresponds to the initial day of the term, i.e. Month initiated on the 12th  day of the calendar month ends on the 11th of next calendar month.</li>
        <li><b>Password</b> - a string of characters which is kept secret, used for authorization to gain access to the User account and which is defined personally by the User. </li>
        <li><b>Price List</b> - a list of fees and charges related to the use of the Services, attached as an Annex no 1 to these Terms.</li>
        <li><b>Registration Form</b> - the form available on the motifray.com website. After the first login, the User completes the required registration data. Only a fully completed Registration Form constitutes a basis for a full Login Activation.</li>
        <li><b>Services</b> - a set of services provided by the Service Provider to the Service Recipient.</li>
        <li><b>Service Provider</b> - Motifray PSA with its registered office in Warsaw at the following address: Tytusa Chałubińskiego 9/2 02-004 Warsaw, Poland, entered into the Register of Entrepreneurs of the National Court Register kept by the District Court in Warsaw, XII Commercial Division of the National Court Register under number KRS: 0001100024, NIP (tax identification number) PL7011200166 amount of share capital PLN 36 000 fully paid; acting as owner and administrator of Motifray.</li>
        <li><b>Service Recipient</b> - any Entrepreneur, who concluded an Agreement for the Service with the Service Provider for purposes directly related to the business or professional activity, or statutory objectives and who makes use of Motifray platform to carry out design work, publish project documentation in selected formats (i.e. graphic JPEG, PNG, Video, PhotoShop, text, PDF), to communicate and to share project documentation with its employees or contractors (Users). The Service Recipient may not be an Entrepreneur subject to any subjective sanctions imposed by the European Union or USA, nor an Entrepreneur with the seat in a country subject to international sanctions imposed by the European Union or USA.</li>
        <li><b>Terms and Conditions</b> - this document, defining the terms of use of Motifray.</li>
        <li><b>User</b> - a natural person, indicated by the Service Recipient, who is the Service Recipient or a natural person legally bound to the Service Recipient by an employment contract, a cooperation agreement or a civil law contract, unequivocally identified by an e-mail address, having access to Motifray through a Login (registered User) or through an unique access link generated by the Service Recipient (non-registered User), undertaking actions within Motifray.</li>
        <li><b>Motifray</b> - a set of online Services available through an https electronic protocol at https://motifray.com, administered by the Service Provider, who holds exclusive rights thereto, and to which the Service Recipient and Users may get access, to an extent and according to the rules defined in these Terms and Conditions.</li></li>
        <li><b>Working days</b> - days from Monday to Friday, excluding public holidays in the Republic of Poland. </li>
      </ol> 
    </p>
  
    <p>
      <h2>§2. Subject of the Terms and Conditions</h2>
      <ol>
        <li>The subject of these Terms and Conditions is to define the terms of the provision of Services within Motifray, consisting in making Motifray accessible and making it possible for the Users to benefit from Motifray Services.</li>
        <li>Services within Motifray are provided by the Service Provider.</li>
        <li>Services within Motifray are provided exclusively to Entrepreneurs.</li>
        <li>The use of Motifray Services is subject to the User's specific access rights to Services and business applications, the period for which the Login has been activated, as well as the Price List in the case of paid services.</li>
      </ol>   
    </p> 
       
    
    <p>
    <h2>§3. Liability of the Service Provider</h2>
    <ol>
      <li>Ensuring the accessibility of Motifray:
       <br> a) The Service Provider undertakes to provide the User with access to Motifray continuously, with the reservation of point b) below;
       <br> b) The Service Provider has the right to temporarily shut down Motifray and stop the provision of Services to the extent necessary to undertake and conduct planned maintenance, repairs and installation of new versions of Motifray. The Service Provider will conduct these works within deadlines announced in the newsletter available to Motifray Users.</li>
      <li>The possibility to access and use Motifray depends on the availability and quality of data transfer connections between the User and User's data transfer service provider, as well as other factors independent from the Service Provider's actions. The Service Provider cannot be held liable for problems and limitations related thereto.</li>
      <li>Subject to the provisions of applicable law, the Service Provider shall be exempted from liability for any damages, including lost profits, suffered by the User or Service Recipient in connection to their use of Services provided by the Service Provider in accordance with these Terms and Conditions.</li>
      <li>The Service Provider is not an agent, a commission agent, an authorized agent or any other kind of legal representative of the Service Recipient. Nor is the Service Provider a party to agreements concluded between Service Recipient and its contractors nor Users.</li>
      <li>Subject to the mandatory provisions of Polish law, the Terms, the Service Provider's liability for the Service provided to the Service Recipient or User is limited to the amount of the loss and together with the liability for contractual penalties will not exceed the amount paid by the Service Recipient for the performance of the given Service during the last 12 months before the occurrence of the loss, and if the Service was provided for a shorter period, for the actual period of providing the Service.</li>
      <li>The Service Provider is not responsible for any content published by Users in the Motifray.</li>
    </ol>   
  </p>
        
  <p>
    <h2>§4. Use of Motifray</h2>
    <ol>
        <li>Access to the Motifray resources and functionalities requires registration in Motifray by the Service Recipient and Login activation, according to the rules defined in §5 below.</li>
        <li>By using Motifray, the every registered User confirms that he has read the Terms and Conditions, accepts all of the provisions hereof and undertakes to comply with those provisions and to pay fees (if applicable) for using the paid Services.</li>
        <li>3. Using Motifray is possible on the condition that the User's system meets the following minimum technical requirements:
          <br> a) web browsers: Firefox version 102 or higher, Google Chrome version 115 or higher, Microsoft Edge version 112 or higher, Safari version 16 or higher, Opera version 106 or higher.
          <br> b) the website is optimized for a 2560 x 1440 screen resolution or higher,
          <br> c) web cookies enabled.</li>
        <li>Each, the Service Recipient and the User, declare and undertake that the actions conducted by him within comply with the Terms and Conditions, applicable law and principles of morality, without prejudice to the rights of third parties or the rights and interests of the Service Provider. The User, on behalf of the Service Recipient and his own, undertakes to comply with the ban on transmitting unlawful content.</li>
        <li>5. In particular, the Service Recipient or the User shall:
          <br> a) use Motifray in a manner not to distort its functioning, particularly by using specified software and devices;
          <br> b) refrain from undertaking the following actions: using the Logins of other Users or making own Login available to other Users; gaining access to Motifray by means different than the interface provided by the Service Provider;
          <br> c) refrain from IT procedures or other procedures aimed at acquiring other Users' Passwords;
          <br> d) cooperate with the Service Provider to a necessary extent, in particular provide all available information necessary to the correct use of Motifray.</li>
        <li>Where the Service Recipient or the User is found to be taking advantage of Motifray in breach of the Terms and Conditions, in particular undertaking actions contrary to those referred to above, the Service Provider may immediately block his Login and Account, temporarily or permanently, and also has the right to undertake all measures necessary to receive compensation for damages related to the Service Recipient or the User's actions.</li>
        <li>The Service Recipient has sole liability for the actions undertaken within Motifray with the use of a valid Login and Password. Service Recipient also holds sole liability for the acts or omissions of its employees, collaborators or contractors, who are using the Password or ID attributed to the User. The Service Recipient has also sole liability for the omissions or actions undertaken within Motifray by the contractors, who have access to Motifray through an unique access link generated by the Service Recipient. </li>
        <li>Each Service Recipient or the User must inform the Service Provider immediately of every event of violation of the rules set forth in these Terms and Conditions.</li>
        <li>Regarding all materials and information published in violation to the provisions of these Terms and Conditions, the Service Provider reserves the right to block or remove such materials and information from Motifray.</li>
  
        </ol>
      </p>
        
      <p>
        <h2>§5. Registration to Motifray</h2>
        <ol>
        <li>The Motifray Login Activation is executed solely by persons authorized by the Service Provider.</li>
        <li>The registration is a two-step procedure. After the User completes the form available on the motifray.com website, the Service Provider verifies the obtained data and activates the login temporarily, which enables the User to log in to Motifray and complete the Registration Form with required data. Once the User completes the data, the Service Provider proceeds to the full Login Activation.</li>
        <li>To obtain Login Activation, the User must confirm that the User has read these Terms and Conditions and agrees to the provisions contained herein.</li>
        <li>A registered User must:
          <br> a) update the data indicated in the Registration Form, regarding both the Service Recipient and the User, immediately after each change thereof; data updates are done within the Account;
          <br> b) keep the Password confidential and refrain from disclosing it to third parties.</li>
        <li>Users may have access to Motifray through an unique access link generated by the Service Recipient (non-registered User) and undertake actions within Motifray. </li>
        <li>The Service Provider cannot be held liable for the accuracy of data provided by Users.</li>
      </ol>
    </p>
        
    <p>
      <h2>§6. Agreement, withdrawal from the use of Motifray</h2>
      <ol>
        <li>The Agreement is concluded for the period indicated by the Service Recipient in the order and provided in the summary of the conditions of the order for the Service and in the confirmation of the order acceptance, which is posted on the Account. </li>
        <li>The service is provided from the moment of Login Activation for an indeterminate period, until the moment of withdrawal from the use of Motifray or the extinction of obligations.</li>
        <li>The Service Recipient can withdraw from using Motifray at any time, by selecting the “Cancel the Motifray subscription” option in the Motifray Workspace Manager panel.</li>
        <li>Withdrawal from the use of Motifray is ineffective regarding the results of actions undertaken by the User within the offered Services before his withdrawal.</li>
        <li>The subscription period is a Month as defined at the beginning of these General Terms. Withdrawal from the use of Motifray in a given subscription period shall in no event entitle the Service Recipient to get a refund of payments already settled for the given period, in accordance with § 9 of the Terms and Conditions and Price List.</li>
        <li>When the subscription period comes to an end, the User's access to the Services covered by the subscription expires.</li>
        <li>The subscription is automatically extended for the next subscription period, subject to point 9.</li>
        <li>If the User communicates his intention to withdraw from using Motifray to the Service Provider before the end of the current subscription period, the subscription expires at the end of the given subscription period.</li>
        <li>The subscription expires at the end of the given subscription period due to lack of payment for the next subscription period, which shall be done until the end of the current subscription period.</li>
        <li>After the subscription expires, at the end of the subscription period, all documents and associated data will be irretrievably deleted (it is not possible to restore them).</li>
        <li>After the subscription expires, the accounts of all Users will be irretrievably deleted, except for the Administrator account, which can be reactivated in the event of re-use of Motifray by the Service Recipient.</li>
        <li>The Service Recipient has the right to change the type of subscription (upgrade or downgrade) during the subscription period. In this case, the cost of a subscription period will be calculated proportionally to the number of days in the month in which the subscription level was active.</li>
      </ol>
    </p>
        
    <p>
      <h2>§7. Privacy Policy and Personal Data</h2>
      <ol>
        <li>Service Provider is the controller of the Users' and Service Recipient's personal data within the meaning of the provisions regulating the protection of personal data. </li>
        <li>Detailed information on the principles of personal data processing is included in the <b>Privacy Policy</b>.</li>
        <li>The Service Provider uses cookies with the aim to collect information on the use of Motifray. Cookies are used to identify the User's device, in particular for the purpose of ensuring that the User's account is used by a person who enters the correct login and the Password attributed to it. Detailed information on the use of cookies and similar technologies is available in the <b>Cookie Policy</b>.</li>
        <li>Service Recipient acknowledges that as part of the services, messages may be sent to the Service Recipient's clients or their representatives. For this purpose, Service Provider will process, including storing certain data of the Recipient's clients or their representatives.</li>
        <li>The use of the services requires the Service Recipient to entrust the Service Provider with the processing of personal data. The rules for entrusting personal data of customers and customer representatives are described in Annex No. 2 to the Terms and conditions, which forms an integral part of the contract. Acceptance of the Terms and conditions implies acceptance of the principles of entrustment of personal data processing.</li>
      </ol>
    </p>
        
    <p>
      <h2>§8. Complaints and Technical Support</h2>
      <ol>
        <li>Complaints related to the functioning of Motifray should be sent to the following email address: contact@motifray.com.</li>
        <li>A complaint should include: User name, login, email address indicated during registration, as well as the reason for the complaint and a detailed description thereof.</li>
        <li>The Service Provider examines the complaint within 14 Working days from the day of its reception and informs the User immediately, by email, of how the complaint shall be dealt with. In the event that the data or information indicated in the complaint are incomplete, the Service Provider requests the person who filed the complaint to complete it before proceeding to its examination. The time of providing additional information by the User extends the period for examining the complaint.</li>
        <li>Complaints resulting from the non-compliance with these Terms and Conditions shall not be examined by the Service Provider.</li>
      </ol>
    </p>
        
    <p><div class="container-extended-blog"></div>
      <h2>§9. Payment</h2>
      <ol>
        <li>The use of Services, requires the Service Recipient to make a payment according to the Motifray Pricing attached as Annex 1 to these Terms and Conditions. This payment shall be increased by due Value Added Tax (VAT), calculated according to applicable law.</li>  
        <li>The Service Recipient may settle the payment referred above through the Stripe Payments Europe, Limited  (stripe.com) made available within Motifray, by charging a credit card of the Service Recipient. The Service Provider is not responsible for the payments and their terms and conditions processed through Stripe Payments Europe, Limited.</li>  
        <li>A VAT invoice shall be issued no later than 7 Working days after settling the payment by the Service Recipient.</li>  
      </ol>
    </p>
        
    <p>
      <h2>§10. Final Provisions</h2>
      <ol>
        <li>The Service Provider stipulates that Motifray, particularly its functionalities and navigation solutions, selection and layout of content presented therein, logotypes, graphic elements and interactive applications, is subject to the exclusive rights of the Service Provider.</li>
        <li>Failing to accept these Terms and Conditions is equal to declining the Services provided by the Service Provider within Motifray.</li>
        <li>The Service Provider reserves the right to amend these Terms and Conditions at any time. Amendments to the Terms and Conditions take effect from the moment they are announced and posted on the Motifray website. For Users, the further use of Motifray shall only be possible if they agree to the amended version of the Terms and Conditions. Provisions of the prior version of the Terms and Conditions shall apply to the ongoing subscription period.</li>
        <li>The Service Provider, as part of the Service it provides, may send a message to the email address given by the User on the website with information such as commercial information on the services and promotions offered by it and the cooperating entities, and other commercial information regarding activities of Service Provider (Newsletter).</li>
        <li>Each Newsletter contains the following information: 
          <br> a) information about the Service Provider, 
          <br> b) the actual content of the Newsletter, 
          <br> c) information about the possibility and method of unsubscribing from the Newsletter. </li>
        <li>The Newsletter is prepared and sent by the Service Provider with variable frequency depending on the volume of materials collected in a given period. </li>
        <li>The Newsletter can be subscribed after registration involving the following subsequent steps: 
          <br> a) giving the name, place of residence and e-mail address of the User in the form posted on the website during the Registration, 
          <br> b) giving consent to receiving the Newsletter sent to the e-mail address indicated in point a) above in accordance with the Terms, unless sending the Newsletter constitutes a legitimate interest of the Service Provider, 
          <br> c) confirmation of correctness of the e-mail address provided by the Client, referred to in point a) above, by clicking on the link sent to the e-mail address. </li>
        <li>If any provision of these Terms and Conditions shall be deemed invalid or unenforceable under applicable law, it shall not affect the validity or enforceability of the remainder of the provisions hereof. A rule closest to the objectives of the invalid provision and to the Terms and Conditions in their entirety shall be applied instead.</li>
        <li>Any disputes that may arise between the Service Provider and the User shall be settled by a court competent for the Service Provider's registered office.</li>
        <li>The provisions of these Terms and Conditions are subject to Polish law. To all matters not settled herein, the applicable law, particularly the provisions of the Civil Code and other acts, shall apply.</li>
        <li>The Terms and Conditions shall enter into force on 22nd July, 2024.</li>
        <li>The following Annexes constitute an integral part of the Terms and Conditions:
          <br> a) Annex No. 1 - Motifray Pricing. The price list of Motifray services is available on the website. 
             <i>Go to the www.motifray.com website and navigate to the Pricing tab in the top navigation bar.</i>
          <br> b) Annex No. 2 - Data Protection Agreement.</li>
      </ol>
    </p>
        
     
  </div>
  
  </body>
  

    `;
}
